
  import { client } from '@/client';
  import { defineComponent } from 'vue';
  import { ElMessage } from 'element-plus';
import { ShopData, ShopDataType } from '@/shared/types/ShopData';
  export default defineComponent ({
    name: "admin-add",
    data: () => {
      return {
        formLabelWidth:"120px",
        params: {
          id:0,
          price:0,
          amount:0,
          mkamount:0,
          give:0,
          name:"",
          type:ShopDataType.GOLD,
          ctime:new Date(),
          utime:new Date()
        } as ShopData,
        paramsRules: {
            price: [
            { required: true, message: "请输入价格", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
          ],
          amount: [
            { required: true, message: "请输入获得数量", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
          ],
          mkamount: [
            { required: true, message: "请输入市场价数量", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
          ],
          give: [
            { required: true, message: "请输入赠送比例", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
          ],
            name: [
            { required: true, message: "请输入名称", trigger: "blur" },
            {
              min: 2,
              max: 20,
              message: "请输入2到20个字符名称",
              trigger: "blur",
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      //新增
      async create() {
        try {
          let res = await client.callApi("shop/Add",this.params);
          if (res.isSucc) {
            ElMessage({
              message: "更新成功^_^",
              type: "success",
            });
            this.$router.go(-1);
          }
        } catch (error) {
          console.log(error);
        }
      },
  
      submit(formName: 'params') {
        let refs:any = this.$refs[formName];
        if (refs) {
          refs.validate((valid: any) => {
            if (valid) {
              this.create();
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        }
      },
    },
  });
  