export interface ShopData {
    id:number,
    /**
     * 价格
     */
    price:number,
    /**
     * 获得数量
     */
    amount:number,
    /**
     * 市场价数量
     */
    mkamount:number,
    /**
     * 赠送比例
     */
    give:number,
    /**
     * 名称
     */
    name:string,
    /**
     * 类型1金币,2贝壳
     */
    type:ShopDataType,
    ctime:Date,
    utime:Date
}
export enum ShopDataType{
    GOLD = '1',
    CONCH = '2'
}